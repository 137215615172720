import React from "react";
import { Row, Col, Image } from "react-bootstrap";

import logo from "../assets/logo2_banner.png"
import background from "../assets/bg.png"

import { FaAddressCard, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaMapMarked, FaTwitterSquare } from "react-icons/fa";
import { SectionTitle } from "../components/SectionTitle";
import { AltFeatureItem } from "../components/AltFeatureItem";


export class Contact extends React.Component {
    render() {
        return (
            <section id="contact" style={{ backgroundImage: `url(${background})` }}>
                <div>
                    <SectionTitle title="Contact US" />
                    <Row>
                        <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                            <Image src={logo} fluid />
                        </Col>
                        <Col xs={{ span: 12, offset: 2 }} sm={{ span: 12, offset: 3 }} lg={{ span: 3, offset: 0 }}>
                            <AltFeatureItem icon={FaAddressCard} title="Contact" text={<>Email: info@akdogan.tech<br />Phone: +90 312 969 09 83</>} />
                        </Col>
                        <Col xs={{ span: 12, offset: 2 }} sm={{ span: 12, offset: 3 }} lg={{ span: 3, offset: 0 }}>
                            <AltFeatureItem icon={FaMapMarked} title="Address" text={<>Akdoğan Teknoloji A.Ş.<br /><br />Ankara Üniversitesi Teknokent<br />Bahçelievler Mah. 319. Sokak No: 35<br />E Blok AZ-07, 06830<br />Gölbaşı/Ankara/Türkiye</>} />
                        </Col>
                    </Row>
                    <Row className="social_media">
                        <Col xs={{span: 12}}>
                            <a href="https://www.facebook.com/akdogantech">{< FaFacebookSquare />}</a>
                            <a href="https://twitter.com/akdogantech">{< FaTwitterSquare />}</a>
                            <a href="https://www.instagram.com/akdogantech/">{< FaInstagramSquare />}</a>
                            <a href="https://www.linkedin.com/company/akdogantech">{< FaLinkedin />}</a>
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
}
