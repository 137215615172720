import React from "react";
import styled from 'styled-components';

const StyledComponent = styled.div`
    margin: 10px 0px 0px 0;
    padding: 0px 0px 0px 0;
    
    h2{
        font-size: 20px;
        @media (max-width: 1366px), (max-height: 800px)  {
            font-size: 16px;
        }
        @media (max-width: 767.98px) {
            font-size: 13px;
        }
    }
    
    
    @media (min-width: 1366px) {
        margin: 10px 0 0 0;
        padding: 5px 0px 5px 0;
    }
    
    @media (max-width: 575.98px) {
        hr{
            margin: 0.3rem;
        }
    }
`;

export class SubSectionTitle extends React.Component {
    constructor(props) {
        super(props);
        this.title = (props.title) ? props.title : "--";
    }
    render() {
        return (
            <StyledComponent className="title_sub_section">
                <h2 className="title">{this.title}</h2>
                <hr />
            </StyledComponent>
        );
    }
}
