import React from "react";
import styled from 'styled-components';

const StyledComponent = styled.div`
    position: relative;
    letter-spacing: 4px;
    text-align: center;
    
    margin: 0px 0px 20px 0;
    padding: 0px 0px 5px 0;

    .title{
        font-size: 30px;
        font-weight: 1000;
        @media (max-width: 1200px) {
            font-size: 24px;
        }
    }
    
    @media (min-width: 1366px) {
        margin: 0px 0px 20px 0;
        padding: 0px 0px 5px 0;
    }
    hr{
        margin: 0 40%;
    }
`;

export class SectionTitle extends React.Component {
    constructor(props) {
        super(props);
        this.title = (props.title) ? props.title : "--";
    }
    render() {
        return (
            <StyledComponent className="title_section">
                <h1 className="title">{this.title}</h1>
                <hr />
            </StyledComponent>
        );
    }
}
