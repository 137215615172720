import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import logo from "../assets/logo2_banner.png"
import beta from "../assets/beta.png"


export class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.onChangeListener = props.onChangeListener;
        this.state = {
            active: 0
        }
    }
    deneme(activePage) {
        this.setState({ active: activePage });
    }
    render() {
        return (
            <Navbar fixed="top" className={"page-" + this.state.active}>
                <Container>
                    <Navbar.Brand>
                        <Image className="imageBeta" src={beta}/>
                        <Image className="imageLogo" src={logo}/>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav
                            onSelect={this.onChangeListener}
                            activeKey={this.state.active}
                        >
                            <Nav.Link key={0} eventKey={0}>Home</Nav.Link>
                            <Nav.Link key={1} eventKey={1}>Sectors</Nav.Link>
                            <Nav.Link key={2} eventKey={2}>Capabilities</Nav.Link>
                            <Nav.Link key={3} eventKey={3}>Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}
