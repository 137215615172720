import React from "react";

import { loremIpsum } from "lorem-ipsum";
import { FaQuestionCircle } from "react-icons/fa";

import styled from 'styled-components';

const StyledComponent = styled.div`
    position: relative;
    padding-left: 15px;
    margin: 10px 0;
    display: flex;
    flex-direction: row;

    .icon{
        text-align: center;
        line-height: 20px; 
        font-size: 20px;
    }
    .body{
        margin: 0 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        .title{
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 400;
            font-size: 11px;
            @media (min-width: 1200px) {
                font-size: 13px;
            }
        }
    }
    @media (min-width: 1366px) {
        margin: 20px 0;
        padding-left: 55px;
        .icon{
            line-height: 28px; 
            font-size: 28px;
        }
        .body .title{
            font-size: 13px;
        }
    }
    @media (max-width: 1199.98px), (max-height: 849px) {
        margin: 5px 0;
        .icon{
            line-height: 15px; 
            font-size: 15px;
        }
        .body .title{
            font-size: 10px;
        }
    }
    @media (max-width: 767.98px), (max-height: 599px) {
        margin: 3px 0;
        .icon{
            line-height: 15px; 
            font-size: 15px;
        }
        .body .title{
            font-size: 8px;
        }
    }
    
`;

export class AltFeatureItem extends React.Component {
    constructor(props) {
        super(props);
        this.title = (props.title) ? props.title : loremIpsum({ count: 2, units: "word" });
        this.text = (props.text) ? props.text : "";
        this.icon = (props.icon) ? props.icon : FaQuestionCircle;
    }

    render() {
        return (
            <StyledComponent className="alt_feature_item">
                <div className="icon">{< this.icon />}</div>
                <div className="body">
                    <h4 className="title">{this.title}</h4>
                    <div className="text">
                        {this.text}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
