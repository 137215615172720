import React from "react";
import { Row, Col } from "react-bootstrap";

import { SectionTitle } from "../components/SectionTitle";
import { AltFeatureItem } from "../components/AltFeatureItem";
import { SubSectionTitle } from "../components/SubSectionTitle";

import { FaCodeBranch, FaArchway, FaTh, FaNetworkWired, FaServer, FaGlobe, FaTerminal, FaPaperPlane, FaDatabase, FaChartLine, FaWrench, FaRobot, FaBrain, FaCode, FaChartPie, FaLayerGroup, FaVial, FaAws, FaCircleNotch, FaDocker, FaMicrochip, FaWarehouse } from "react-icons/fa";

export class Capabilities extends React.Component {
    render() {
        return (
            <section id="capabilities">
                <div>
                    <SectionTitle title="Capabilities" />
                    <Row>
                        <SubSectionTitle title="Embedded System Development" />
                        <Row>
                            <Col xs={6} xl={4}><AltFeatureItem title="System Architecture Design" icon={FaArchway} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Network Communications" icon={FaNetworkWired} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Graphical User Interfaces" icon={FaChartLine} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Real Time Operating Systems" icon={FaMicrochip} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Embedded Linux" icon={FaServer} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Command Control Applications" icon={FaPaperPlane} /></Col>
                            {/* <Col xs={6} xl={4}><AltFeatureItem title="Petalinux/Yocto" icon={FaWrench} /></Col> */}
                        </Row>
                    </Row>
                    <Row>
                        <SubSectionTitle title="Web Development and Cloud Applications" />
                        <Row>
                            <Col xs={6} xl={4}><AltFeatureItem title="Backend Development" icon={FaCodeBranch} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Frontend Development" icon={FaGlobe} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Databases" icon={FaDatabase} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Cont. Integration / Cont. Delivery" icon={FaCircleNotch} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Amazon Web Services" icon={FaAws} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Microservices Architecture" icon={FaTh} /></Col>
                        </Row>
                    </Row>
                    <Row>
                        <SubSectionTitle title="Data Analysis and Machine Learning" />
                        <Row>
                            <Col xs={6} xl={4}><AltFeatureItem title="Reinforcement Learning" icon={FaBrain} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Data Warehouses" icon={FaWarehouse} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Robotics" icon={FaRobot} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Data Analytics" icon={FaLayerGroup} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Data Visualization" icon={FaChartPie} /></Col>
                            <Col xs={6} xl={4}><AltFeatureItem title="Sensor Fusion" icon={FaVial} /></Col>
                        </Row>
                    </Row>
                </div>
            </section>
        );
    }
}
