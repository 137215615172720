import React from "react";

import { loremIpsum } from "lorem-ipsum";
import { FaQuestionCircle } from "react-icons/fa";
import styled from 'styled-components';

const StyledComponent = styled.div`
    margin: 20px 0;
    text-align: center;

    .icon{
        line-height: 1.2;
        font-size: 42px;
        /* color: #111; */
    }
    .title{
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 400;
        font-size: 14px;
        /* color: #111; */
        margin: 16px 0 15px;
    }
`;

export class FeatureItem extends React.Component {
    constructor(props) {
        super(props);
        this.title = (props.title) ? props.title : loremIpsum({ count: 2, units: "word" });
        this.icon = (props.icon) ? props.icon : FaQuestionCircle;
    }

    render() {
        return (
            <StyledComponent className="feature_item">
                <div className="icon">{< this.icon />}</div>
                <h3 className="title">{this.title}</h3>
            </StyledComponent>
        );
    }
}
