import React, { createRef } from "react";

import { Capabilities } from './Capabilities';
import { Sectors } from './Sectors';
import { Landing } from './Landing';
import { Contact } from './Contact';
import { Navigation } from './Navigation';
import ReactPageScroller from 'react-page-scroller';

export class OnePageScroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentPage: null };
        this.navRef = createRef();
    }
    handlePageChange = number => {
        this.setState({ currentPage: number });
        this.navRef.current.deneme(number);
    };
    onExternalChange = page => {
        page = parseInt(page);
        this.handlePageChange(page);
    };

    render() {
        return (
            <>
                <Navigation onChangeListener={this.onExternalChange} ref={this.navRef} />
                <ReactPageScroller
                    pageOnChange={this.handlePageChange}
                    customPageNumber={this.state.currentPage}
                    renderAllPagesOnFirstRender={true}
                >
                    <Landing></Landing>
                    <Sectors></Sectors>
                    <Capabilities></Capabilities>
                    <Contact></Contact>
                </ReactPageScroller>
            </>
        );
    }
}