import './styles/style.scss';
import './App.scss';

import Container from 'react-bootstrap/Container';

import { OnePageScroll } from './sections/OnePageScroll.jsx';

function App() {
  return (
    <Container fluid>
      <OnePageScroll />
    </Container>
  );
}

export default App;