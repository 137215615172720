import React from "react";
import { Row, Col } from "react-bootstrap";

import { SectionTitle } from "../components/SectionTitle";
import { FaGlobe, FaMicrochip, FaPlane, FaShieldAlt } from "react-icons/fa";
import { FeatureItem } from "../components/FeatureItem";

export class Sectors extends React.Component {
    render() {
        return (
            <section id="sectors">
                <div>
                    <Row><SectionTitle title="Sectors" /></Row>
                    <Row>
                        <Col xs={6} xl={3}><FeatureItem title="Defence Industry" icon={FaShieldAlt} /></Col>
                        <Col xs={6} xl={3}><FeatureItem title="Unmanned Systems" icon={FaPlane} /></Col>
                        <Col xs={6} xl={3}><FeatureItem title="Internet of Things" icon={FaMicrochip} /></Col>
                        <Col xs={6} xl={3}><FeatureItem title="Web Technologies" icon={FaGlobe} /></Col>
                    </Row>
                </div>
            </section>
        );
    }
}
