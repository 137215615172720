import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import logo from "../assets/logo2_banner.png"
import background from "../assets/bg.png"
import { FeatureItem } from "../components/FeatureItem";

import { FaBriefcase, FaChalkboardTeacher, FaGraduationCap } from "react-icons/fa";

export class Landing extends React.Component {
    render() {
        return (
            <section id="landing" style={{ backgroundImage: `url(${background})` }}>
                <div>
                    <Card>
                        <Card.Img className="h-50" variant="top" src={logo} />
                        <Card.Body>
                            <Row>
                                <Col xs={12} sm={4}><FeatureItem title="Solution Partnership" icon={FaBriefcase} /></Col>
                                <Col xs={12} sm={4}><FeatureItem title="Consultancy" icon={FaChalkboardTeacher} /></Col>
                                <Col xs={12} sm={4}><FeatureItem title="Training" icon={FaGraduationCap} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </section>
        );
    }
}
